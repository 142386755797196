import React from "react";
import UserImg from "./body-components/user-img";
import Contact from "./../contact";
import Portfolio from "./portfolio";
import Homepage from "./body-components/homepage";
import { Switch, Route } from "react-router-dom";

const Body = () => {
  return (
    <>
      <div
        className="container-fluid h-100"
        style={{ minHeight: "90vh", marginTop: "60px" }}
      >
        {/* User Logo Image */}
        <UserImg />
        {/* end of user Logo Image */}
        {/* main portfolio body */}
        <div className="row mt-3 h-100">
          <div className="col">
            <Switch>
              <Route path="/" exact>
                <Homepage />
              </Route>
              <Route path="/contact">
                <Contact />
              </Route>
              <Route path="/portfolio">
                <Portfolio />
              </Route>
            </Switch>
          </div>
        </div>
        {/* end of main portfolio body */}
      </div>
    </>
  );
};

export default Body;
