import React, { useState } from "react";
import emailjs from "emailjs-com";
import { Helmet } from "react-helmet";

const Contact = () => {
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [tattooDescription, setTattooDescription] = useState("");
  const [tattooPlacement, setTattooPlacement] = useState("");
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const sendEmail = (e) => {
    e.preventDefault();
    setSubmitDisabled(true);
    setErrorMessage(false);
    setSuccessMessage(false);

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAIL_SERVICE_ID,
        process.env.REACT_APP_EMAIL_TEMPLATE_ID,
        e.target,
        process.env.REACT_APP_EMAIL_USER_ID
      )
      .then(() => {
        resetState();
        setSuccessMessage(true);
      })
      .catch(() => {
        setSubmitDisabled(false);
        setErrorMessage(true);
      });
  };

  const uploadImage = (file) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "image/png" },
      body: JSON.stringify({ file }),
    };
    fetch(
      "https://www.googleapis.com/upload/drive/v3/files?uploadType=media",
      requestOptions
    )
      .then((response) => console.log(response))
      .then((data) => console.log(data));
  };

  const resetState = () => {
    setFullName("");
    setEmail("");
    setContactNumber("");
    setTattooDescription("");
    setTattooPlacement("");
  };

  const updateResponseMessages = () => {
    setSuccessMessage(false);
    setErrorMessage(false);
  };

  return (
    <div className="container-fluid">
      <Helmet>
        <title>Kirsten Tattoos - Book with me!</title>
        <meta
          name="description"
          content="Glasgow based tattoo artist, Expert in Anime and Gaming tattoos1"
        />
        <meta
          name="keywords"
          content="Glasgow, Tattoo, Glasgow Tattoo artist, Anime, Gaming, black and white, color, sleeve, anime tattoo, gaming tattoo, anime tattoo artist, gaming tattoo artist, glasgow tattoo artist, uk tattoo artist, anime glasgow tattoo artist, best anime tattoo artist, best gaming tattoo artist, best tattoo artist glasgow, best tatoo artist UK"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.kirstentattoos.com/" />
      </Helmet>
      <div className="row">
        <div className="col-lg-6 col-md-12">
          <div className="contact-us-form-container p-md-3">
            <form onSubmit={sendEmail} className="contact-form">
              <div className="text-center mb-3">
                <h3>Contact / Bookings</h3>
              </div>
              <input
                type="hidden"
                name="to_email"
                value={process.env.REACT_APP_EMAIL}
              />
              <div className="mb-3">
                <label htmlFor="fullName" className="form-label">
                  Full Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="fullName"
                  name="from_name"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  onFocus={() => updateResponseMessages()}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email address
                </label>
                <input
                  type="email"
                  name="reply_to"
                  className="form-control"
                  aria-describedby="emailHelp"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onFocus={() => updateResponseMessages()}
                  required
                />
                <div id="emailHelp" className="form-text">
                  <i>We'll never share your email with anyone else.</i>
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="contactNumber" className="form-label">
                  Contact Number
                </label>
                <input
                  type="number"
                  name="contact_number"
                  className="form-control"
                  id="contactNumber"
                  value={contactNumber}
                  onChange={(e) => setContactNumber(e.target.value)}
                  onFocus={() => updateResponseMessages()}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="tattooDescription" className="form-label">
                  Tattoo description
                </label>
                <input
                  type="text"
                  name="tattoo_description"
                  className="form-control"
                  id="tattooDescription"
                  value={tattooDescription}
                  onChange={(e) => setTattooDescription(e.target.value)}
                  onFocus={() => updateResponseMessages()}
                  required
                />
                <div id="tattooDescription" className="form-text">
                  <i>
                    Help me understand what you are looking to get i.e. Anime,
                    realistic, Gaming sleeve etc
                  </i>
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="tattooPlacement" className="form-label">
                  Area on body
                </label>
                <input
                  type="text"
                  name="tattoo_placement"
                  className="form-control"
                  id="tattooPlacement"
                  value={tattooPlacement}
                  onChange={(e) => setTattooPlacement(e.target.value)}
                  onFocus={() => updateResponseMessages()}
                  required
                />
              </div>
              {successMessage ? (
                <div className="bg-success text-white p-2 mb-3">
                  Your request has been sent successfully. Thank you.
                </div>
              ) : null}
              {errorMessage ? (
                <div className="bg-warning text-white p-2 mb-3">
                  Your request has failed to send. Please try again. If this
                  problem persists, please email {process.env.REACT_APP_EMAIL}{" "}
                  manually.
                </div>
              ) : null}
              <button
                type="submit"
                className="btn btn-primary"
                disabled={submitDisabled}
              >
                Submit
              </button>
            </form>
          </div>
        </div>
        <div className="col-lg-6 col-md-12 my-auto">
          <div className="text-center mb-3 mt-5">
            <h3>Location</h3>
          </div>
          <iframe
            title="googleMaps"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2238.8900860505305!2d-4.26607858390814!3d55.86457339083325!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48884565d294536f%3A0xecd7b9289e42ff6a!2s200%20Bath%20St%2C%20Glasgow%20G2%204HG!5e0!3m2!1sen!2suk!4v1625525242553!5m2!1sen!2suk"
            style={{ width: "100%", height: "400px" }}
            loading="lazy"
          ></iframe>
          <p>Address: 3/2 200 Bath Street, Glasgow, G2 4HG</p>
        </div>
      </div>
    </div>
  );
};
export default Contact;
