import React, { Component } from "react";
import UserLogo from "./../../../images/logo-color.png";
// import headerBar from "./../../../images/header-bar.jpg";

class UserImg extends Component {
  state = {};
  render() {
    return (
      <>
        <div className="row">
          <div className="col user-img-bg p-0">
            <div
              className="user-img-overlay p-1"
              // style={{
              //   backgroundImage: "url(" + headerBar + ")",
              //   backgroundPosition: "center",
              // }}
            ></div>
            <div className="user-img-interface user-img-centering"></div>
            <img
              className="user-img user-img-centering"
              src={UserLogo}
              alt="user-img"
            />
          </div>
        </div>
      </>
    );
  }
}

export default UserImg;
