import React from "react";
import { Helmet } from "react-helmet";

const Homepage = () => {
  return (
    <>
      <Helmet>
        <title>Kirsten Tattoos - Anime &amp; Gaming Tattoos</title>
        <meta
          name="description"
          content="Glasgow based tattoo artist, Expert in Anime and Gaming tattoos1"
        />
        <meta
          name="keywords"
          content="Glasgow, Tattoo, Glasgow Tattoo artist, Anime, Gaming, black and white, color, sleeve, anime tattoo, gaming tattoo, anime tattoo artist, gaming tattoo artist, glasgow tattoo artist, uk tattoo artist, anime glasgow tattoo artist, best anime tattoo artist, best gaming tattoo artist, best tattoo artist glasgow, best tatoo artist UK"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.kirstentattoos.com/" />
      </Helmet>
      <div className="container pt-5 mt-5 homepage-container">
        <h2>About Me</h2>
        <p>
          Before getting into tattooing, I obtained a Masters in Animation at
          Glasgow School of Art but not wanting to spend the rest of my days
          behind a computer screen I started looking for another creative path.
        </p>
        <p>
          In 2014 I finally started my career in the world of tattooing and
          haven't looked back since. With a background in animation, I enjoy
          tattooing related subjects including anime, manga and geeky inspired
          designs. Colour or black and grey as long as it makes my inner geek
          happy.
        </p>
        <p>
          Have a look at my portfolio{" "}
          <a
            href="/portfolio"
            style={{ fontWeight: "bold", cursor: "pointer" }}
          >
            here
          </a>{" "}
          and get booked in for some amazing ink!
        </p>
        <p style={{ fontFamily: "HoneyScript", fontSize: "35px" }}>
          Kirsten xx
        </p>
        <div className="mt-5">
          <h2>Follow my socials!</h2>
          <div
            className="row mx-auto"
            style={{ cursor: "pointer" }}
            onClick={() => {
              window.open("https://www.instagram.com/kirstentattoos/");
            }}
          >
            <span className="w-100" style={{ lineHeight: "20px" }}>
              <i className="fab fa-instagram-square fa-2x"></i>
              <span className="ml-2" style={{ verticalAlign: "super" }}>
                Instagram - kirstentattoos
              </span>
            </span>
          </div>
          <div
            className="row mx-auto"
            style={{ cursor: "pointer" }}
            onClick={() => {
              window.open("https://www.facebook.com/KirstenTattoos/");
            }}
          >
            <span className="w-100 mr-2" style={{ lineHeight: "20px" }}>
              <i className="fab fa-facebook-square fa-2x"></i>
              <span className="ml-2" style={{ verticalAlign: "super" }}>
                Facebook/KirstenTattoos
              </span>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Homepage;
