import Main from "./components/main";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import "@fortawesome/fontawesome-free/css/all.css";
import { Helmet } from "react-helmet";
import LogRocket from "logrocket";
LogRocket.init("nqoi5f/kirsten-tattoos");

function App() {
  return (
    <div className="App">
      <Router>
        <Helmet>
          <title>Kirsten Tattoos</title>
          <meta
            name="description"
            content="Kirsten Tattos - Glasgow based tattoo artist with expertise in Anime and Gaming tattoos"
          />
          <meta name="robots" content="index, follow" />
          <link rel="canonical" href="https://www.kirstentattoos.com/" />
          <link rel="canonical" href="https://www.kirstentattoos.co.uk/" />
        </Helmet>
        <Main />
      </Router>
    </div>
  );
}

export default App;
