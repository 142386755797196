import React from "react";

const Footer = () => {
  return (
    <>
      <div className="footer">
        Copyright {new Date().getFullYear()} KirstenTattoos, 3/2 200 Bath
        Street, Glasgow, G2 4HG.
      </div>
    </>
  );
};

export default Footer;
