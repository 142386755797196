import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const Portfolio = () => {
  useEffect(() => {
    let js;
    if (document.getElementById("EmbedSocialHashtagScript")) {
      window.location.reload();
      return;
    }
    js = document.createElement("script");
    js.id = "EmbedSocialHashtagScript";
    js.src = "https://embedsocial.com/cdn/ht.js";
    document.getElementsByTagName("head")[0].appendChild(js);
  });
  return (
    <div className="container pt-5 mt-5">
      <Helmet>
        <title>Kirsten Tattoos - Portfolio</title>
        <meta
          name="description"
          content="Glasgow based tattoo artist, Expert in Anime and Gaming tattoos1"
        />
        <meta
          name="keywords"
          content="Glasgow, Tattoo, Glasgow Tattoo artist, Anime, Gaming, black and white, color, sleeve, anime tattoo, gaming tattoo, anime tattoo artist, gaming tattoo artist, glasgow tattoo artist, uk tattoo artist, anime glasgow tattoo artist, best anime tattoo artist, best gaming tattoo artist, best tattoo artist glasgow, best tatoo artist UK"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.kirstentattoos.com/" />
      </Helmet>
      <div
        className="embedsocial-hashtag"
        data-ref="a476869230eef03007a47dc916c920b19e031ed4"
      >
        <a
          className="feed-powered-by-es"
          href="https://embedsocial.com/social-media-aggregator/"
          target="_blank"
          title="KirstenTattoos"
          rel="noreferrer"
        >
          .
        </a>
      </div>
    </div>
  );
};

export default Portfolio;
