import React, { useState } from "react";
import { Link } from "react-router-dom";

const Header = () => {
  const [mobileNavOpened, setMobileNavOpened] = useState(false);

  return (
    <>
      <div className="nav-container">
        <nav>
          <h2 style={{ paddingTop: "10px" }}>Kirsten Tattoos</h2>
          <div className="mobile-nav-container desktop-hidden float-right p-2 mt-2 mr-3">
            <div
              className="mobile-nav w-100"
              style={{
                right: mobileNavOpened ? "0" : "-100%",
              }}
              id="mobileNav"
              onClick={(e) => {
                if (e.target.id === "mobileNav")
                  setMobileNavOpened(mobileNavOpened ? false : true);
              }}
            >
              <div className="mobile-nav-inner container-fluid">
                <div className="row pl-3 p-2">
                  <span
                    className="mobile-nav-option"
                    onClick={() => setMobileNavOpened(false)}
                  >
                    <Link to="/">Home</Link>
                  </span>
                </div>
                <div className="row pl-3 p-2">
                  <span
                    className="mobile-nav-option"
                    onClick={() => setMobileNavOpened(false)}
                  >
                    <Link to="/portfolio">Portfolio</Link>
                  </span>
                </div>
                <div className="row pl-3 p-2">
                  <span
                    className="mobile-nav-option"
                    onClick={() => setMobileNavOpened(false)}
                  >
                    <Link to="/contact">Bookings</Link>
                  </span>
                </div>
              </div>
            </div>
            <i
              className="fas fa-bars fa-2x"
              onClick={() => setMobileNavOpened(mobileNavOpened ? false : true)}
            ></i>
          </div>
          <ul
            className="hvr-underline-from-center mobile-hidden"
            onClick={() => {
              window.location.href = "/";
            }}
          >
            Home
          </ul>
          <ul
            className="hvr-underline-from-center mobile-hidden"
            onClick={() => {
              window.location.href = "/portfolio";
            }}
          >
            Portfolio
          </ul>
          <ul
            className="mobile-hidden"
            onClick={() => {
              window.location.href = "/contact";
            }}
          >
            Bookings
          </ul>
        </nav>
      </div>
    </>
  );
};
export default Header;
